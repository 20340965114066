<template>
  <div class="violationReport">
    <!-- [nh_00]处理中 -->
    <div class="vant-header" v-if="status === 'nh_00'">
      <div class="title">
        <van-icon name="more-o" /><span>{{ statusName }}</span>
      </div>
      <p>已收到您的反馈，正在处理中，感谢您的支持！</p>
    </div>
    <!--
        nh_00 处理中 nh_01 已处理 nh_02 已拒绝 nh_03 已取消 04 待预审
        [nh_01]已处理( passed )【已处理+ 处理时间+ 处理结果】
        [nh_02]已拒绝( close )【处理时间 + 拒绝时间】
        [nh_03]已取消( close )【处理时间】
    -->
    <div v-if="status === 'nh_01' || status === 'nh_02' || status === 'nh_03'">
      <div class="vant-header">
        <div class="title">
          <van-icon name="passed" /><span>{{ statusName }}</span>
        </div>
        <p v-if="status === 'nh_01' || status === 'nh_03'">处理时间:{{ updatedTime }}</p>
        <p v-if="status === 'nh_02'">拒绝时间:{{ updatedTime }}</p>
        <P v-if="remark" style="margin-top:6px; padding-left:26px;padding-right:26px;text-align:left">处理结果：{{remark}}</P>
      </div>
      <!-- <div class="resultStyle" v-if="remark">
        <van-field v-model="remark" rows="1" autosize label="处理结果" type="textarea" readonly />
      </div> -->
    </div>

    <div v-if="formConf" class="vant-parser" :style="state !=='add' ? 'margin-top: -35px' : ''">
      <parser ref="parser" :key="key2" :form-conf="formConf" @submit="sumbitForm1" />
    </div>
    <!-- [nh_00]处理中状态显示 -->
    <div class="footer" v-if="!stateBoolean && status === 'nh_00'">
      <div class="btns">
        <van-button plain type="info" @click="showCancelReport" style="margin-right: 10px">
          取消反馈
        </van-button>
        <van-button plain type="info" @click="updateInfo">修改信息</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant';
import { formatTime } from '@/utils/tools';
import Parser from '@/components/vant-parser/Parser';
import {
  selectMaxRelease,
  addFeedbackHandling,
  selectFeedbackHandlingDetail,
  cancelReport,
  updateFeedbackHandling
} from '@/api/feedback';
import { selectFeedBackPersonInfo } from '@/api/home';
export default {
  components: {
    Parser
  },
  data() {
    return {
      key2: +new Date(),
      formConf: null,
      /* state: add-新增；see-查看；edit-修改 */
      state: null,
      /* stateBoolean: true-页面可编辑；false：页面不可编辑 */
      stateBoolean: true,
      feedbackHandlingId: null,
      formReleaseId: null,
      status: '',
      statusName: '',
      updatedTime: '',
      remark: '' // 处理结果
    };
  },
  mounted() {
    this.into();
  },
  methods: {
    /* 页面初始化 */
    into() {
      this.state = this.$route.query.state;
      this.feedbackHandlingId = this.$route.query.id !== undefined ? this.$route.query.id : null;
      this.stateBoolean = this.state === 'see' ? false : true;
      this.selectMaxRelease();
    },
    /* 获取表单样式 */
    async selectMaxRelease() {
      let arg = {
        businessCode: '0102',
        moduleCode: '0102'
      };
      const { status, resultData } = await selectMaxRelease(arg);
      if (status === 'complete' && resultData) {
        this.formReleaseId = resultData.formReleaseId;
        let data = JSON.parse(resultData.content);
        if (!this.stateBoolean) {
          data = { ...data, readonly: true };
        }
        this.formConf = data;
        if (this.state === 'add') {
          this.selectFeedBackPersonInfo();
        }
        if (this.feedbackHandlingId) {
          this.selectFeedbackHandlingDetail(this.feedbackHandlingId);
        }
      }
    },
    //获取反馈人信息
    async selectFeedBackPersonInfo() {
      const {status, resultData} = await selectFeedBackPersonInfo();
      if (status === 'complete') {
        let data = {
          contacts: resultData.realName,
          telephone: resultData.phone
        }
        this.$nextTick(() => {
          this.$refs.parser.fillForm(data);
        });
      }
    },
    /* 提交数据查询 */
    async selectFeedbackHandlingDetail(id) {
      const { status, resultData } = await selectFeedbackHandlingDetail(id);
      if (status === 'complete') {
        this.status = resultData.status;
        this.statusName = resultData.statusName;
        this.updatedTime = formatTime(resultData.updatedTime);
        this.remark = resultData.remark;
        let data = JSON.parse(resultData.formContent);
        this.$nextTick(() => {
          this.$refs.parser.fillForm(data);
        });
      }
    },
    /* 新增提交 */
    async addFeedbackHandling(data) {
      const { status, resultData } = await addFeedbackHandling(data);
      if (status === 'complete') {
        let processingDays = resultData.processingDays;
        this.feedbackHandlingId = resultData.feedbackHandlingId;
        this.handerDialog('提交', processingDays);
      }
    },
    /* 修改更新 */
    async updateFeedbackHandling(arg) {
      const { status, resultData } = await updateFeedbackHandling(arg);
      if (status === 'complete') {
        let processingDays = resultData.processingDays;
        this.feedbackHandlingId = resultData.feedbackHandlingId;
        this.handerDialog('修改', processingDays);
      }
    },
    /* 成功后弹框 */
    handerDialog(dialogName, processingDays) {
      Dialog.confirm({
        title: `您的意见反馈已${dialogName}成功！`,
        message:
          processingDays !== undefined
            ? `预计在${processingDays}个工作日内完成，请耐心等待，可至“我的办理”中查看办理进度。`
            : '工作人员会尽快与您联系，请耐心等待，可至“我的反馈”中查看办理进度。',
        confirmButtonText: '查看记录',
        confirmButtonColor: '#3C7EF0',
        cancelButtonText: '返回首页',
        cancelButtonColor: '#767E8C'
      })
        .then(() => {
          // 查看记录
          this.$router.replace({ query: { state: 'see', id: this.feedbackHandlingId } });
          this.stateBoolean = false;
          this.state = 'see';
          this.key2 = +new Date();
          this.formConf = { ...this.formConf, readonly: true };
          this.selectFeedbackHandlingDetail(this.feedbackHandlingId);
        })
        .catch(() => {
          // 返回首页
          this.$router.push({ path: '/' });
        });
    },
    /* 表单数据提交 */
    sumbitForm1(data) {
      if (this.state === 'add') {
        let submitArg = {
          feedbackCode: '0102',
          formReleaseId: this.formReleaseId,
          formContent: JSON.stringify(data)
        };
        this.addFeedbackHandling(submitArg);
      } else {
        let updateArg = {
          feedbackCode: '0102',
          formReleaseId: this.formReleaseId,
          formContent: JSON.stringify(data),
          feedbackHandlingId: this.feedbackHandlingId
        };
        this.updateFeedbackHandling(updateArg);
      }
    },
    // 取消
    showCancelReport() {
        Dialog.confirm({
            message: '您确定取消反馈吗？',
            confirmButtonText: '取消反馈',
            confirmButtonColor: '#3C7EF0',
            cancelButtonText: '点错了',
            cancelButtonColor: '#767E8C',
        })
        .then(() => {
          this.cancelReport();
        })
        .catch(() => {
          console.log('取消反馈')
        });
    },
    // 取消
    async cancelReport() {
      let arg = this.feedbackHandlingId;
      const { status, resultData } = await cancelReport(arg);
      if (status === 'complete') {
        Toast('该业务已取消');
        // 回显页面。状态变为已取消
        // this.$router.replace({ query: { state: 'see', id: this.feedbackHandlingId } });
        this.stateBoolean = false;
        this.state = 'see';
        this.key2 = +new Date();
        this.formConf = { ...this.formConf, readonly: true };
        this.selectFeedbackHandlingDetail(this.feedbackHandlingId);
      }
    },
    // 修改信息
    updateInfo(data) {
      this.$router.replace({ query: { state: 'edit', id: this.feedbackHandlingId } });
      this.stateBoolean = true;
      this.state = 'edit';
      this.key2 = +new Date();
      this.formConf = { ...this.formConf, readonly: false };
      this.selectFeedbackHandlingDetail(this.feedbackHandlingId);
    }
  }
};
</script>

<style lang="less" scoped>
.violationReport {
  .vant-header {
    font-size: 14px;
    padding-top: 15px;
    padding-bottom: 50px;
    font-size: 14px;
    color: #fff;
    background-image: linear-gradient(180deg, #1B78FF 0%, rgba(47,164,255,0.64) 61%, rgba(156,205,249,0.00) 100%);
    .title {
      text-align: center;
      span {
        margin-left: 10px;
      }
    }
    p {
      margin: 0px;
      text-align: center;
    }
  }
  .resultStyle {
    margin: 16px;
    border-radius: 8px;
  }
  .vant-parser {
    margin: 16px 16px 80px 16px;
    overflow: hidden;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    padding: 15px 0px 15px 0px;
    background: #ffffff;
    width: 100%;
    position: fixed;
    bottom: 0px;

    .btns {
      width: 100%;
      text-align: center;
      margin: 0px 16px 0px 16px;
      /deep/.van-button--normal {
        width: calc(100% / 2 - 10px);
        border-radius: 8px;
      }
    }
  }
}
</style>
